<template>
    <v-container fluid>
      <v-row class="mt-1">
        <v-col>
          <btn-voltar to="/veiculos/modelo" />
        </v-col>
      </v-row>
  
      <modelo-veiculo-campos
        :carregando-salvar="carregandoSalvar"
        :carregando-buscar="carregandoBuscar"
        :validacao-formulario="validacaoFormulario"
        :modelo-veiculo="modeloVeiculo"
        @salvar="salvar"
      />
    </v-container>
  </template>
  
  <script>
    import modelos from '@/api/modelos'
    import _ from 'lodash'
  
    export default {
      components: {
        ModeloVeiculoCampos: () => import('./ModeloVeiculoCampos'),
      },
  
      watch: { 

      modeloVeiculo: {
        immediate: true,
        handler (val) {
          if (_.isEmpty(val)) return false

          this.modeloNovo = val
        },
      },
    },
      data () {
        return {
          carregandoSalvar: false,
          carregandoBuscar: false,
          validacaoFormulario: {},
          modeloVeiculo: {},
        }
      },
  
      mounted () {
        this.buscar()
      },
  
      methods: {
        async buscar () {
          try {
            this.carregandoBuscar = true
  
            const resposta = await modelos.buscar(this.$route.params.id)
  
            this.modeloVeiculo = resposta.data 
          } catch (e) {
            this.$router.push('/veiculos/modelo', () => this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) }))
          } finally {
            this.carregandoBuscar = false
          }
        },
  
        async salvar (modelo) {
          this.carregandoSalvar = true
  
          try {
 
            let dados = {
              id:modelo.id,
              veiculo_marca_id:modelo.veiculo_marca,
              descricao: modelo.descricao
            }
            await modelos.atualizar(modelo.id, dados)
  
            this.carregandoSalvar = false
  
            this.$router.push('/veiculos/modelo', () => this.$snackbar.mostrar({ cor: 'success', mensagem: this.$mensagens._('sucesso_editar') }))
          } catch (e) {
            if (e.response.status === 422) {
              this.validacaoFormulario = this.$erroApi.validacao(e)
              return
            }
  
            this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
          } finally {
            this.carregandoSalvar = false
          }
        },
      },
  
    }
  </script>
  